import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent} from 'react';
import axios from 'axios';
import { Box, TextField, Button, List, ListItem, Avatar, Grid, Typography, IconButton, InputAdornment, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FormData from 'form-data';
import RecommendationCard from './RecommendationCard';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressBar from './ProgressBar';
import ComparisonChartCard from './ComparisonChartCard';
import { showTextChOrEn } from './utils';
import ComparisonList from './ComparisonList';
import { getLocalStorage } from './useStorage';
import ProgressBarMobile from './ProgressBarMobile';
import ChatSelection from './ChatSelection';
import Enquiry from './Enquiry';
import Sidebar from './sidebar';
import { History } from '@mui/icons-material'; 

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  name: string;
  avatar: string;
  products?: Product[];
  chart?: any;
}

interface Product {
  title: string;
  url: string;
  price: string;
  reasons: string;
  imgUrl: string;
}

interface PBRecommendationProps {
  onClose: () => void;
  store: string;
}

const Recommendation: React.FC<PBRecommendationProps> = ({ onClose, store }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [canCompare, setCanCompare] = useState(false);
  const [comparisonList, setComparisonList] = useState<Product[]>([]);
  const [isComapreMethod, setIsCompareMethod] = useState(false)
  const [enquiryMode, setEnquiryMode] = useState(false)
  const [enquiryProduct, setEnquiryProduct] = useState<Product | undefined>(undefined)
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  const handleEnquiry = (product:Product) =>{
    setEnquiryMode(true)
    setEnquiryProduct(product)
  }

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getSessionID();
  }, []);

  useEffect(() => {
    scrollToBottom();
    setCanCompare(messages.length > 1);
  }, [messages]);

  useEffect(() => {
    if (sessionId && messages.length !== 0) {
      // Retrieve existing session data from localStorage
      const existingData = localStorage.getItem('sessionData');
      let sessionDataArray = [];
  
      if (existingData) {
        // Parse existing data if it exists
        sessionDataArray = JSON.parse(existingData);
      }
  
      // Find the index of the existing session data
      const sessionIndex = sessionDataArray.findIndex((session: { sessionId: string; }) => session.sessionId === sessionId);
  
      if (sessionIndex !== -1) {
        // Update the existing session data but keep the original timestamp
        sessionDataArray[sessionIndex] = {
          ...sessionDataArray[sessionIndex],
          messages: messages,
          compare:isComapreMethod
        };
      } else {
        // Add the new session data to the array if sessionId is unique with new timestamp
        const sessionData = {
          timestamp: new Date().toISOString(),
          sessionId: sessionId,
          messages: messages,
          compare:isComapreMethod,
          store: store
        };
        sessionDataArray.push(sessionData);
      }
  
      // Save the updated array back to localStorage
      localStorage.setItem('sessionData', JSON.stringify(sessionDataArray));
    }
  }, [messages, sessionId]);  

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const chatbotAPI = async (method:string, url:string, formdata?:FormData) => {
    const BaseUrl = 'https://chatbot.pricegrabber.co.nz/';
    if (method == "POST"){
      const response = await axios.post(BaseUrl + url, formdata);
      return response
    } else {
      const response = await axios.get(BaseUrl + url);
      return response
    }
  }

  const handleSend = async () => {
    setLoading(true);
    setIsButtonEnabled(false);
    if (input.trim()) {
      const newMessage: Message = {
        id: messages.length + 1,
        text: input,
        sender: 'user',
        name: 'User',
        avatar: '/path-to-user-avatar.jpg',
      };
      setMessages([...messages, newMessage]);
      setInput('');

      try {
        const chatbotFormData = new FormData();
        chatbotFormData.append('language', getLanguageSetting());
        chatbotFormData.append('query', input);
        chatbotFormData.append('store', store);
        chatbotFormData.append('session_id', sessionId);
        chatbotFormData.append('classification', isComapreMethod? 'compare':'recommendation');
        // const chatbotResponse = await chatbotAPI('POST','chatbot',chatbotFormData);

        const classificationFormData = new FormData();
        classificationFormData.append('session_id', sessionId);
        classificationFormData.append('store', store);
        classificationFormData.append('query', input);
        // const classificationResponse = await chatbotAPI('POST','classification',classificationFormData);

        const [chatbotResponse, classificationResponse] = await Promise.all([
          chatbotAPI('POST', 'chatbot', chatbotFormData),
          chatbotAPI('POST', 'classification', classificationFormData)
        ]);

        if (classificationResponse.data == 'recommendation'){
          const botResponse: Message = {
            id: messages.length + 1,
            text: chatbotResponse.data.message,
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
          };
          setLoading(false);
          setIsCompareMethod(false);
          setMessages(prevMessages => [...prevMessages, botResponse]);
        } 
        else if (classificationResponse.data == 'others'){
          const chatbotFormData = new FormData();
          chatbotFormData.append('language', getLanguageSetting());
          chatbotFormData.append('query', input);
          chatbotFormData.append('store', store);
          chatbotFormData.append('session_id', sessionId);
          chatbotFormData.append('classification', 'others');
          const chatbotResponse = await chatbotAPI('POST', 'chatbot', chatbotFormData)
          const botResponse: Message = {
            id: messages.length + 1,
            text: chatbotResponse.data.message,
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
          };
          setLoading(false);
          setIsCompareMethod(false);
          setMessages(prevMessages => [...prevMessages, botResponse]);
        } 
        else {
          if(isComapreMethod == false){
            getProducts("compare",input)
          } else{
            const botResponse: Message = {
              id: messages.length + 1,
              text: chatbotResponse.data.message,
              sender: 'bot',
              name: 'Bot',
              avatar: '/path-to-bot-avatar.jpg',
            };
            setLoading(false);
            setIsCompareMethod(true);
            setMessages(prevMessages => [...prevMessages, botResponse]);
          }
        }
      } catch (error) {
        setLoading(false);
        const errorMessage: Message = {
          id: messages.length + 1,
          text: showTextChOrEn('很抱歉,与服务器通讯错误，我们正在紧急修复中，请您稍后再试', 'Sorry, there was a communication error with the server. We are urgently repairing it. Please try again later'),
          sender: 'bot',
          name: 'Bot',
          avatar: '/path-to-bot-avatar.jpg',
        };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
        console.error('Error sending message:', error);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setIsButtonEnabled(e.target.value.trim() !== '');
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const getProducts = async (classification:string, query?:string) => {
    try {
      setLoading(true);
      setProgressBar(true);
      const loadingMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn("正在查找产品,请您耐心等待20秒...","Searching Products, please wait patiently for 20 seconds..."),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
        products: [],
      };
      setMessages(prevMessages => [...prevMessages, loadingMessage]);

      if(classification == 'recommendation'){
        const formData = new FormData();
        formData.append('session_id', sessionId);
        formData.append('store', store);
        formData.append('language',getLanguageSetting()); 
        const response = await chatbotAPI('POST','recommendation', formData);
        const products = response.data;
        if (products.length == 0){
          const errorMessage: Message = {
            id: messages.length + 1,
            text: showTextChOrEn('很抱歉，我们未能根据您的需求找到合适的产品', 'Sorry, we were unable to find a suitable product based on your needs'),
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
          };
          setMessages(prevMessages => [...prevMessages, errorMessage]);
        } else{
          const recommendationMessage: Message = {
            id: messages.length + 1,
            text: showTextChOrEn('以下是根据您的提问查找到的产品，您可以选择单个产品进行提问或选择产品集进行对比','Based on your query, here are the products we have found. You can choose to inquire about a single product or select multiple products for comparison.'),
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
            products: products,
          };
          setMessages(prevMessages => [...prevMessages, recommendationMessage]);
        }
        setProgressBar(false);
        setLoading(false);
        
      }
      if(classification == 'compare'){
        const formData = new FormData();
        formData.append('session_id', sessionId);
        formData.append('store', store);
        formData.append('language',getLanguageSetting()); 
        formData.append('query',query); 
        const response = await chatbotAPI('POST','comparison', formData);
        const products = response.data;
        if (products.length == 0){
          const errorMessage: Message = {
            id: messages.length + 1,
            text: showTextChOrEn('很抱歉，我们未能根据您的需求找到合适的产品', 'Sorry, we were unable to find a suitable product based on your needs'),
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
          };
          setMessages(prevMessages => [...prevMessages, errorMessage]);
        } else{
          const recommendationMessage: Message = {
            id: messages.length + 1,
            text: showTextChOrEn('以下是根据您的提问查找到的产品，您可以选择单个产品进行提问或选择产品集进行对比','Based on your query, here are the products we have found. You can choose to inquire about a single product or select multiple products for comparison.'),
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
            products: products,
          };
          setMessages(prevMessages => [...prevMessages, recommendationMessage]);
        }
        setProgressBar(false);
        setLoading(false);
        setIsCompareMethod(true);
      }
    } catch (error) {
      console.error('Error fetching recommendation:', error);
      const errorMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn('很抱歉，未能找到商品', 'Sorry, we were unable to find the products'),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
      setProgressBar(false);
      setLoading(false);
    }
  };

  const handleAddToCompare = (product: Product) => {
    setComparisonList(prevList => {
      if (prevList.some(p => p.url === product.url)) {
        return prevList.filter(p => p.url !== product.url);
      } else {
        return [...prevList, product];
      }
    });
  };

  const getLanguageSetting = () => {
    if (typeof window === 'undefined') {
      return 'en'
    }
  
    if (getLocalStorage('language') === 'zh-cn') {
      return 'zh-cn'
    }
  }

  const handleRecommendationCompare = async () => {
    try {
      setLoading(true);
      const loadingMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn("正在生成产品对比,请您耐心等待20秒...","Generating product comparison, please wait patiently for 20 seconds..."),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
        products: [],
      };
      setMessages(prevMessages => [...prevMessages, loadingMessage]);

      const formData = new FormData();
      formData.append('session_id', sessionId);
      formData.append('store', store);
      formData.append('products', JSON.stringify(comparisonList.map(p => p.url)));
      formData.append('language', getLanguageSetting());
      handleClearComparison()

      const response = await chatbotAPI('POST','productsCompare', formData);
      const botResponse: Message = {
        id: messages.length + 1,
        text: response.data,
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
      };
      setMessages(prevMessages => [...prevMessages, botResponse]);
      setIsCompareMethod(true)
    } catch (error) {
      console.error('Error comparing products:', error);
      const errorMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn('很抱歉，未能生成产品对比结果', 'Sorry, we were unable to generate a comparison results'),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
      setProgressBar(false);
      setLoading(false);
    }

    try {
      const loadingMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn('正在根据您的提问生成产品对比表格,请您耐心等待20秒...', 'Generating the product comparison table based on your inquiry, please wait patiently for 20 seconds...'),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
      };
      setMessages(prevMessages => [...prevMessages, loadingMessage]);

      const formData = new FormData();
      formData.append('session_id', sessionId);
      formData.append('store', store);
      formData.append('language', getLanguageSetting());

      const response = await chatbotAPI('POST','comparisonchart', formData);
      const chart = response.data.chart;
      const recommendationMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn('根据您的提问，以下是产品对比表格：', 'Based on your inquiry, here is the product comparison table:'),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
        chart: chart
      };
      setMessages(prevMessages => [...prevMessages, recommendationMessage]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching comparison chart:', error);
      const errorMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn('很抱歉，未能生成对比表格', 'Sorry, we were unable to generate a comparison table'),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
      setLoading(false);
    };
  };

  const getSessionID = async (): Promise<void> => {
    try {
      setMessages([])
      setIsCompareMethod(false)
      const response = await chatbotAPI('GET','token');
      if (typeof response.data === 'string') {
        setSessionId(response.data);
      }
    } catch (error) {
      console.error('Error fetching the session ID:', error);
    }
  };

  const handleClearComparison = () => {
    setComparisonList([]);
  };

  const handleRemoveFromComparison = (productUrl: string) => {
    setComparisonList(prevList => prevList.filter(p => p.url !== productUrl));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
      }}
    >
      <Sidebar 
        messages={messages} 
        setMessages={setMessages} 
        sessionId={sessionId} 
        setSessionId={setSessionId} 
        historyOpen={historyOpen} 
        setHistoryOpen={setHistoryOpen}
        setIsCompareMethod={setIsCompareMethod}
        store={store}
        />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p:{ xs: 3, sm: 5 } 
        }}
      >
        <Box>
        <IconButton size="small" onClick={onClose}>
          <Box mr={1}>
            <ArrowBackIcon />
          </Box>
          {isMobile? '' : showTextChOrEn('返回','Back')}
        </IconButton>
        </Box>
        <Box ml={2}>
          <IconButton size="small" onClick={() => { setHistoryOpen(true); }}>
            <Box mr={1}>
              <History />
            </Box>
            {isMobile ? '' : showTextChOrEn('历史会话', 'History')}
          </IconButton>
        </Box>
      </Box>
      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          marginBottom: '16px',
        }}
      >
        {messages.length == 0 ? <ChatSelection store={store}  messages={messages} setMessages={setMessages}/> :''}
        {messages.map((msg) => (
          <ListItem
            key={msg.id}
            sx={{
              justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {msg.sender === 'bot' && (
              <Avatar alt={msg.name} src={msg.avatar} sx={{ marginRight: 2 }} />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
              }}
            >
              <Typography variant="subtitle2">{msg.name}</Typography>
              <Box
                sx={{
                  backgroundColor: msg.sender === 'user' ? '#e0f7fa' : '#e8eaf6',
                  borderRadius: '8px',
                  padding: '8px',
                  marginBottom: '8px',
                }}
              >
                <Typography variant="body2">{msg.text}</Typography>
              </Box>
              {msg.products && enquiryMode ? <Enquiry onClose={() => setEnquiryMode(false)} store={store} sessionId={sessionId} product={enquiryProduct!} chatbotAPI={chatbotAPI}/> : ''}
              {msg.products && !isMobile ? (
               <Grid container spacing={3}>
                  {msg.products.map((product, index) => (
                    <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                    <RecommendationCard
                      key={index}
                      product={product}
                      onAddToCompare={handleAddToCompare}
                      handleEnquiry={handleEnquiry}
                      isCompared={comparisonList.some(p => p.url === product.url)}
                      isCompareMethod={isComapreMethod}
                    />
                    </Grid>
                  ))}
                </Grid>
              ): null}
              {
              msg.products && isMobile ? (
                <Box style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
                {msg.products.map((product, index) => (
                  <Box key={index} style={{ flex: '0 0 auto', margin: '0 10px' }}>
                    <RecommendationCard
                      product={product}
                      onAddToCompare={handleAddToCompare}
                      handleEnquiry={handleEnquiry}
                      isCompared={comparisonList.some(p => p.url === product.url)}
                      isCompareMethod={isComapreMethod}
                    />
                  </Box>
                ))}
              </Box>
              ):null}
              {msg.chart && (
                  <Box
                    sx={{
                      backgroundColor: '#e8eaf6',
                      borderRadius: '8px',
                      padding: '8px',
                      marginTop: '8px',
                      overflowX: 'auto',
                    }}
                  >
                    <ComparisonChartCard data={msg.chart} />
                  </Box>
                )}
            </Box>
            {msg.sender === 'user' && (
              <Avatar alt={msg.name} src={msg.avatar} sx={{ marginLeft: 2 }} />
            )}
          </ListItem>
        ))}
        <div ref={messagesEndRef} />
      </List>
      {progressBar && isMobile && (
        <Box sx={{ width: '100%' }}>
          <ProgressBarMobile duration={2500} />
        </Box>
      )}
      {progressBar && !isMobile && (
        <Box sx={{ width: '100%' }}>
          <ProgressBar duration={2500} />
        </Box>
      )}
      {loading && (
        <Box sx={{ width: '100%', mt: 3, mb:1 }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '8px 16px',
        }}
      >

        {isMobile && comparisonList.length > 0 ? (
          <Button
            variant="outlined"
            onClick={handleRecommendationCompare}
            disabled={comparisonList.length === 0 || loading}
            sx={{ marginRight: '16px' }}
          >
            {showTextChOrEn("对比","Compare")}
          </Button>
        ) : ''}
        {/* {state == 'compare' && messages.length > 0 ? (
          <Button
            variant="outlined"
            onClick={handleCompare}
            disabled={messages.length <= 1 || loading}
            sx={{ marginRight: '16px' }}
          >
            {showTextChOrEn("对比","Compare")}
          </Button>
        ) : ''} */}
          {comparisonList.length == 0 ? (
          <Button
            variant="outlined"
            onClick={()=>{getProducts("recommendation")}}
            disabled={!canCompare || loading}
            sx={{ marginRight: '16px' }}
          >
            {showTextChOrEn("查询产品","Search Products")}
          </Button>
        ) : ''}
        { messages.length > 0 ? 
          <Button
          variant="outlined"
          color="error"
          onClick={getSessionID}
          disabled={!canCompare || loading}
        >
          {showTextChOrEn("清空聊天","Clear")}
        </Button> 
        :''
      }
      </Box>
      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        m: 1,
        borderRadius: 5, // Rounded corners
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '800px', // Max width of the input box
          bgcolor: '#f5f5f5',
          borderRadius: '50px', // Elliptical border
          p:1
        }}
      >
        <TextField
          variant="outlined"
          value={input}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          fullWidth // Takes full width of the available space
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '50px', // Elliptical border
              '& fieldset': {
                border: 'none', // No border
              },
            },
          }}
          placeholder="Send a Message to me"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  onClick={handleSend}
                  disabled={!isButtonEnabled}
                  sx={{
                    bgcolor: 'black',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(215,215,215,1)',
                      color: isButtonEnabled ? 'black' : 'gray',
                    },
                    '&:disabled': {
                      bgcolor: 'rgba(215,215,215,1)',
                      color: 'white',
                    }
                  }}                  
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
      {/* Hide ComparisonList in mobile view */}
      {!isMobile && comparisonList.length > 0 && (
        <ComparisonList 
          comparisonList={comparisonList}
          onClear={handleClearComparison}
          onCompare={handleRecommendationCompare}
          onRemove={handleRemoveFromComparison}
        />
      )}
    </Box>
  );
};

export default Recommendation;