import { createTheme, Theme } from '@mui/material/styles'
import { error, grey, primary, secondary, success, warning } from './themeColors'
import { deviceSize } from './constants'

const fontSize = 15

const fontFamily = ['Barlow', 'Arimo', 'sans-serif'].join(',')

const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: deviceSize.xs,
      sm: deviceSize.sm,
      md: deviceSize.md,
      lg: deviceSize.lg,
      xl: deviceSize.xl,
    },
  },
})

// Create a theme instance.
const bazarTheme = createTheme({
  palette: {
    primary: {
      ...primary,
      light: primary[100],
    },
    secondary,
    error,
    warning: {
      ...warning,
      light: 'rgb(255, 231, 208)',
      contrastText: 'rgb(243,199,161)',
      main: primary[300],
    },
    success,
    text: {
      primary: grey[900],
      secondary: grey[800],
      disabled: grey[400],
    },
    divider: grey[200],
    grey: { ...grey },
    background: {
      default: 'white',
    },
  },
  typography: {
    fontSize,
    fontFamily,
    htmlFontSize: 16,
    body1: { fontSize },
    body2: { fontSize },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ul: {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
        p: {
          lineHeight: 1.75,
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        button: {
          fontFamily,
          fontSize,
        },
        '.MuiRating-sizeSmall': {
          fontSize: '20px',
        },
        // Loading bar nprogress
        '#nprogress .bar': {
          position: 'fixed',
          top: 0,
          left: 0,
          height: '3px !important',
          borderRadius: '0px 300px 300px 0px !important',
          zIndex: 1031,
          background: `${secondary.main} !important`,
          overflow: 'hidden',
        },
        '#nprogress .peg': {
          boxShadow: `0 0 10px ${secondary.main}, 0 0 5px ${secondary.main} !important`,
        },
        '#nprogress .spinner': {
          display: 'none !important',
        },
        // Image Viewer
        '[aria-label="close image viewer"]': {
          top: 'calc(16px + env(safe-area-inset-top)) !important',
          right: 'calc(16px + env(safe-area-inset-right)) !important',
        },
        '[aria-label="previous image"]': {
          left: 'calc(16px + env(safe-area-inset-left)) !important',
        },
        '[aria-label="next image"]': {
          right: 'calc(16px + env(safe-area-inset-right)) !important',
        },
        // Flash Sale Carousel
        '.alice-carousel__dots': {
          position: 'absolute !important',
          bottom: '1rem !important',
          width: '100% !important',
        },
        '.alice-carousel__dots-item': {
          width: '1.1rem !important',
          height: '1.1rem !important',
          backgroundColor: `${grey['100']}C3 !important`,
          border: `1px solid ${primary.main} !important`,
        },
        '.alice-carousel__dots-item:not(:last-child)': {
          marginRight: '0.7rem !important',
        },
        '.alice-carousel__dots-item:hover': {
          backgroundColor: `${grey['100']}C3 !important`,
        },
        '.alice-carousel__dots-item.__active': {
          backgroundColor: `${primary.main} !important`,
        },
        '.alice-carousel__prev-btn, .alice-carousel__next-btn': {
          position: 'absolute !important',
          textAlign: 'center !important',
          width: 'auto !important',
          top: '50% !important',
        },
        '.alice-carousel__prev-btn': {
          left: '1rem !important',
        },
        '.alice-carousel__next-btn': {
          right: '1rem !important',
        },
        '.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item': {
          color: `${primary.main} !important`,
          fontSize: '1.3rem !important',
          background: `${primary['100']}A3 !important`,
          width: '2.3rem !important',
          borderRadius: '0.7rem',
          fontWeight: 600,
        },
        '.alice-carousel__slide-info': {
          background: `${primary['100']}F3 !important`,
          color: `${primary.main} !important`,
        },
        //Body Scanner
        'body.scanner-active': {
          background: 'transparent !important',
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: 'capitalize',
          minWidth: 0,
          minHeight: 0,
        },
      },
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [customTheme.breakpoints.up(deviceSize.sm)]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
  },
})

const theme = { ...customTheme, ...bazarTheme }

theme.shadows[0] = 'none'
theme.shadows[1] = '0px 1px 3px rgba(3, 0, 71, 0.09)'
theme.shadows[2] = '0px 4px 16px rgba(43, 52, 69, 0.1)'
theme.shadows[3] = '0px 8px 45px rgba(3, 0, 71, 0.09)'
theme.shadows[4] = '0px 0px 28px rgba(3, 0, 71, 0.01)'

export type MuiThemeProps = Theme

export default theme
