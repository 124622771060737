import React from 'react';
import { Box, Typography, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { showTextChOrEn } from './utils';

interface ChatSelectionProps {
  store: string;
  messages: any;
  setMessages: React.Dispatch<React.SetStateAction<any>>;
}

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  name: string;
  avatar: string;
  products?: Product[];
  chart?: any;
}

interface Product {
  title: string;
  url: string;
  price: string;
  reasons: string;
  imgUrl: string;
}

const floatingButtonStyles = (delay: number): React.CSSProperties => ({
  animation: `floatIn 0.5s ease-in-out forwards`,
  animationDelay: `${delay}s`,
  opacity: 0,
});

const wipeUpStyles: React.CSSProperties = {
  animation: 'wipeUp 1s ease-in-out forwards',
  opacity: 0,
};

const ChatSelection: React.FC<ChatSelectionProps> = ({ store, messages, setMessages }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelection = (state:string) => {
    if (store == 'PBTech' && state == 'recommendation')
    {
      const loadingMessage: Message = {
        id: messages.length + 1,
        text: showTextChOrEn("'我是PB Tech产品推荐智能助手，请问您有什么产品需求？例如，您可以说：“我想给女儿买一个礼物，请帮我推荐一款手机。” 我会很高兴为您提供帮助。'","I am PB Tech's Product Recommendation Smart Assistant. How can I assist you with your product needs? For example, you can say, 'I want to buy a gift for my daughter; please recommend a phone.' I will be happy to help you."),
        sender: 'bot',
        name: 'Bot',
        avatar: '/path-to-bot-avatar.jpg',
        products: [],
      };
      setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
    }
    
    if (store == 'PBTech' && state == 'compare')
      {
        const loadingMessage: Message = {
          id: messages.length + 1,
          text: showTextChOrEn('我是PB Tech产品智能对比助手，可以比较两个产品在参数、功能等方面的不同。您可以说："请帮我比较一下iPhone 14和iphone 15 pro在处理器和摄像头方面的区别" 我会很高兴为您提供帮助。', 'I am the PB Tech Product Comparison Smart Assistant. I can compare two products in terms of parameters, features, and more. You can say, "Please help me compare the iPhone 14 and iPhone 15 Pro in terms of processor and camera differences." I will be happy to assist you.'),
          sender: 'bot',
          name: 'Bot',
          avatar: '/path-to-bot-avatar.jpg',
          products: [],
        };
        setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
      }

      if (store == 'PBTech' && state == 'enquiry')
        {
          const loadingMessage: Message = {
            id: messages.length + 1,
            text: showTextChOrEn('我是PB产品智能问答助手，可以为您解答产品参数、功能等方面的疑问。您可以说："请告诉我Iphone 15是否支持无线充电?"，我会很高兴为您提供帮助。', 'I am the PB Product Enquiry Smart Assistant. I can answer questions about product parameters, features, and more. You can say, "Please tell me if the iPhone 15 supports wireless charging?" I will be happy to assist you.'),
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
            products: [],
          };
          setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
        }

      if (store == 'Bunnings' && state == 'recommendation')
          {
            const loadingMessage: Message = {
              id: messages.length + 1,
              text: showTextChOrEn('我是Bunnings产品推荐智能助手，请问您需要什么产品？您可以告诉我您的需求，例如：“我想要改善我的花园，请帮我推荐一些园艺工具。” 我会很高兴为您提供帮助。','I am the Bunnings Product Recommendation Smart Assistant. How can I assist you with your product needs? For example, you can tell me, "I want to improve my garden; please recommend some gardening tools." I will be happy to help you.'),
              sender: 'bot',
              name: 'Bot',
              avatar: '/path-to-bot-avatar.jpg',
              products: [],
            };
            setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
          }

      if (store == 'Bunnings' && state == 'compare')
            {
              const loadingMessage: Message = {
                id: messages.length + 1,
                text: showTextChOrEn('我是Bunnings产品智能对比助手，可以比较两个Bunnings产品在参数、功能等方面的不同。您可以说：“请帮我比较一下Ozito 12V Home Drill Driver Kit和Ozito PXC 18V Cordless Drill Driver Kit PXDDK-250C电钻在电池容量和最大钻孔直径方面的区别” 我会很高兴为您提供帮助。', 'I am the Bunnings Product Comparison Smart Assistant. I can compare two Bunnings products in terms of parameters, features, and more. You can say, "Please help me compare the Ozito 12V Home Drill Driver Kit and the Ozito PXC 18V Cordless Drill Driver Kit PXDDK-250C in terms of battery capacity and maximum drilling diameter." I will be happy to assist you.'),
                sender: 'bot',
                name: 'Bot',
                avatar: '/path-to-bot-avatar.jpg',
                products: [],
              };
              setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
            }

        if (store == 'Bunnings' && state == 'enquiry')
              {
                const loadingMessage: Message = {
                  id: messages.length + 1,
                  text: showTextChOrEn('我是Bunnings产品智能问答助手，可以回答关于产品的功能、用途和性能等方面的问题。您可以说："请告诉我Ryobi 18V电钻是否适合家庭使用以及它的主要特点是什么?" 我会很高兴为您提供帮助。', 'I am the Bunnings Product Enquiry Smart Assistant. I can answer questions about product features, uses, and performance. You can say, "Please tell me if the Ryobi 18V drill is suitable for home use and what its main features are?" I will be happy to assist you.'),
                  sender: 'bot',
                  name: 'Bot',
                  avatar: '/path-to-bot-avatar.jpg',
                  products: [],
                };
                setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
              }

        if (store == 'Chemistwarehouse' && state == 'recommendation')
                {
                  const loadingMessage: Message = {
                    id: messages.length + 1,
                    text: showTextChOrEn("我是Chemist Warehouse产品推荐智能助手，请问您需要什么产品？您可以告诉我您的需求，例如：“我想给家人买一些维生素，请帮我推荐几款。” 我会很高兴为您提供帮助。","I am the Chemist Warehouse Product Recommendation Smart Assistant. How can I assist you with your product needs? For example, you can tell me, 'I want to buy some vitamins for my family; please recommend a few options.' I will be happy to help you."),
                    sender: 'bot',
                    name: 'Bot',
                    avatar: '/path-to-bot-avatar.jpg',
                    products: [],
                  };
                  setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
                }
  
        if (store == 'Chemistwarehouse' && state == 'compare')
                {
                    const loadingMessage: Message = {
                      id: messages.length + 1,
                      text: showTextChOrEn('我是Chemist Warehouse产品智能对比助手，可以比较两个Chemist Warehouse产品在参数、功能等方面的不同。您可以说：“请帮我比较一下Cetaphil洁面乳和La Roche-Posay洁面乳在适用肤质和主要成分方面的区别” 我会很高兴为您提供帮助。','I am the Chemist Warehouse Product Comparison Smart Assistant. I can compare two Chemist Warehouse products in terms of parameters, features, and more. You can say, "Please help me compare the Cetaphil cleanser and La Roche-Posay cleanser in terms of suitable skin types and main ingredients." I will be happy to assist you.'),
                      sender: 'bot',
                      name: 'Bot',
                      avatar: '/path-to-bot-avatar.jpg',
                      products: [],
                    };
                    setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
                }

        if (store == 'Chemistwarehouse' && state == 'enquiry')
                  {
                      const loadingMessage: Message = {
                        id: messages.length + 1,
                        text: showTextChOrEn('我是Chemist Warehouse产品智能问答助手，可以回答关于护肤品的功效、成分和适用人群等方面的问题。您可以说："请告诉我Avene Cleanance Expert乳液是否能祛痘以及它的主要成分是什么?" 我会很高兴为您提供帮助。', 'I am the Chemist Warehouse Product Enquiry Smart Assistant. I can answer questions about skincare products, their effects, ingredients, and suitable users. You can say, "Please tell me if the Avene Cleanance Expert Emulsion can help with acne and what its main ingredients are?" I will be happy to assist you.'),
                        sender: 'bot',
                        name: 'Bot',
                        avatar: '/path-to-bot-avatar.jpg',
                        products: [],
                      };
                      setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
                  }
  }

  return (
    <div>
      <style>
        {`
          @keyframes floatIn {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes wipeUp {
            0% {
              transform: translateY(100%);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
      <Box
        sx={{
          mt:{ xs: 0, sm: 10 } ,
          p: { xs: 0, sm: 2 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
        }}
      >
        {store == 'PBTech'? 
        <img
          src="/assets/icons/stores/Pbtech.png"
          alt="Robot"
          style={{ width: "80px", height: "60px", ...wipeUpStyles }}
        /> : ""}
        {store == 'Bunnings'? 
        <img
          src="/assets/icons/stores/Bunnings.png"
          alt="Robot"
          style={{ width: "80px", height: "80px", ...wipeUpStyles }}
        /> : ""}
        {store == 'Chemistwarehouse'? 
        <img
          src="/assets/icons/stores/Chemistwarehouse.png"
          alt="Robot"
          style={{ width: "80px", height: "80px", ...wipeUpStyles }}
        /> : ""}
        <Typography variant="h6" gutterBottom align="center" sx={{ m: { xs: 1, sm: 4 },...wipeUpStyles }}>
          {showTextChOrEn("欢迎使用"+ store + "购物助手", "Welcome to " + store + " Shopping Assistant")}
        </Typography>
        <Typography variant="body1" align="center" sx={{ m: { xs: 2, sm: 0 } }}>
          {showTextChOrEn(
            "我们很高兴为您提供产品推荐、产品对比和产品问答服务。如有任何需要，请随时告诉我们。",
            "We are delighted to provide you with product recommendations, comparisons, and Q&A services. Please feel free to let us know if you need any assistance."
          )}
        </Typography>
        {isMobile ? 
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {/* Row 1 */}
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt:2 }}>
            <Card
              sx={{
                ...floatingButtonStyles(0.2),
                width: '150px',
                height:'110px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleSelection("recommendation")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src="/assets/icons/recommendation.png"
                alt="recommendation"
                sx={{
                  mt: 4,
                  width: '80%',
                  height: '50%',
                  objectFit: 'contain',
                }}
              />
                <Typography variant="body2">Recommendation</Typography>
              </CardContent>
            </Card>
          </Box>
          {/* Row 2 */}
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2, mt:1,}}>
            <Card
              sx={{
                ...floatingButtonStyles(0.4),
                width: '130px',
                height:'110px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleSelection("compare")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src="/assets/icons/compare.png"
                alt="compare"
                sx={{
                  width: '75%',
                  height: '75%',
                  objectFit: 'contain',
                }}
              />
                <Typography variant="body2">Bunnings</Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                ...floatingButtonStyles(0.6),
                width: '130px',
                height:'110px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => handleSelection("enquiry")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src="/assets/icons/query.png"
                alt="query"
                sx={{
                  width: '75%',
                  height: '75%',
                  objectFit: 'contain',
                }}
              />
                <Typography variant="body2">Enquiry</Typography>
              </CardContent>
            </Card>
          </Box>
        </Box> 
        :
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', mt:8 }}>
        <Card
          sx={{
            ...floatingButtonStyles(0.2),
            m: 3,
            width: '150px',
            height: '120px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            src="/assets/icons/recommendation.png"
            alt="recommendation"
            sx={{
              pt: 1,
              pb: 1.5,
              width: '50%',
              height: '60%',
              objectFit: 'contain',
            }}
            onClick={() => handleSelection('recommendation')}
          />
            <Typography variant="body2">Recommendation</Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            ...floatingButtonStyles(0.4),
            m: 3,
            width: '150px',
            height: '120px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            src="/assets/icons/compare.png"
            alt="compare"
            sx={{
              width: '80%',
              height: '75%',
              objectFit: 'contain',
            }}
            onClick={() => handleSelection('compare')}
          />
            <Typography variant="body2">Compare</Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            ...floatingButtonStyles(0.6),
            m: 3,
            width: '150px',
            height:'120px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            src="/assets/icons/query.png"
            alt="query"
            sx={{
              width: '80%',
              height: '75%',
              objectFit: 'contain',
            }}
            onClick={() => handleSelection('enquiry')}
          />
            <Typography variant="body2">Enquiry</Typography>
          </CardContent>
        </Card>
      </Box>
}
      </Box>
    </div>
  );
};

export default ChatSelection;










