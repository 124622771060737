import React from 'react';

interface Data {
    [key: string]: any;
}

interface TransposedData {
    Header: string;
    [key: string]: any;
}

const transposeData = (data: Data[]): TransposedData[] => {
    if (data.length === 0) return [];
    const keys = Object.keys(data[0]);
    return keys.map(key => {
        const row: TransposedData = { Header: key };
        data.forEach((item, index) => {
            row[`Column${index + 1}`] = item[key];
        });
        return row;
    });
};

interface ProductTableProps {
    data: Data[];
}

const ProductTable: React.FC<ProductTableProps> = ({ data }) => {
    const transposedData = React.useMemo(() => transposeData(data), [data]);

    return (
        <table style={{ border: 'solid 1px black', width: '100%', marginTop: '20px' }}>
            <tbody>
                {transposedData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {Object.entries(row).map(([key, value], cellIndex) => {
                            if (key === 'Header') {
                                return (
                                    <td
                                        key={cellIndex}
                                        style={{
                                            padding: '10px',
                                            border: 'solid 1px gray',
                                            background: 'papayawhip',
                                            fontWeight: 'bold',
                                            color: 'black',
                                        }}
                                    >
                                        {value}
                                    </td>
                                );
                            }
                            return (
                                <td
                                    key={cellIndex}
                                    style={{
                                        padding: '10px',
                                        border: 'solid 1px gray',
                                        background: 'papayawhip',
                                        fontWeight: 'lighter',
                                        color: 'black',
                                    }}
                                >
                                    {value}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProductTable;










