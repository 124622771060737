import React, { useState, useEffect } from 'react';
import { showTextChOrEn } from './utils';
import { CircularProgress, Box, Typography } from '@mui/material';

const ProgressBarMobile: React.FC<{ duration: number }> = ({ duration }) => {
  const steps = [
    showTextChOrEn("问题汇总", "Summary"),
    showTextChOrEn("问题分析", "Analysis"),
    showTextChOrEn("数据库检索", "Database Search"),
    showTextChOrEn("答案生成", "Answer Generation")
  ];

  const [progress, setProgress] = useState(0);

  // Define the step completion percentages
  const stepCompletionTimes = [
    duration * 0.25,
    duration * 0.50,
    duration * 0.75,
    duration * 1.00
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= duration) {
          clearInterval(timer);
          return duration;
        }
        return prevProgress + 1;
      });
    }, 1000 / 60); // Update roughly 60 times per second

    return () => clearInterval(timer);
  }, [duration]);

  const getStepColor = (index: number) => {
    return progress >= stepCompletionTimes[index] ? '#ffa500' : '#ccc';
  };

  const totalProgress = Math.min((progress / duration) * 100, 100);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ 
        width: '100%', 
        mt:'80px',
        mb: '60px', 
       }}
    >
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={totalProgress}
          size={150} // Reduced the size to 150
          thickness={4}
          sx={{ color: '#ffa500' }}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {Math.round(totalProgress)}%
          </Typography>
        </Box>
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {steps.map((step, index) => {
            const angle = (index / steps.length) * 360;
            const rotation = `rotate(${angle}deg) translate(110px) rotate(-${angle}deg)`; // Adjusted translate distance to 110px
            const isCompleted = progress >= stepCompletionTimes[index];
            return (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                flexDirection="column"
                sx={{
                  position: 'absolute',
                  transform: rotation,
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '20px', // Reduced the size of the step markers
                    height: '20px', // Reduced the size of the step markers
                    borderRadius: '50%',
                    backgroundColor: getStepColor(index),
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px', // Adjusted the font size to fit the smaller markers
                    mb: '4px',
                  }}
                >
                  {isCompleted ? '✔' : '○'}
                </Box>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    fontWeight: 'bold', 
                    whiteSpace: 'normal', // Allow wrapping
                    wordWrap: 'break-word', // Enable word wrapping
                    width: '80px', // Define the max width for each step text
                    textAlign: 'center' // Center-align the text
                  }}
                >
                  {step}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBarMobile;