import { getLocalStorage, getSessionStorage} from './useStorage'

export const showTextChOrEn = (chText: string, enText: string) => {
  if (typeof window === 'undefined') {
    return enText
  }

  if (getLocalStorage('language') === 'zh-cn') {
    return chText
  }

  const lang = getSessionStorage('lang')
  if (lang === 'chs') {
    return chText
  } else {
    return enText
  }
}