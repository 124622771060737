export const deviceSize = {
  xs: 431,
  sm: 769,
  md: 1025,
  lg: 1441,
  xl: 1921,
}

const mobileTitleHeaderHeight = 57
const mobileNavHeight = 53
const mobileHeaderHeight = 119
const topbarHeight = 39
const headerHeight = 79
const headerNavBarHeight = 59

export const layoutConstant = {
  topbarHeight: topbarHeight,
  headerHeight: headerHeight,
  headerNavBarHeight: headerNavBarHeight,
  desktopHeaderAllHeight: topbarHeight + headerHeight + headerNavBarHeight,
  sidenavWidth: 280,
  containerWidth: 1200,
  mobile: {
    mobileTitleHeaderHeight: mobileTitleHeaderHeight,
    mobileNavHeight: mobileNavHeight,
    mobileHeaderHeight: mobileHeaderHeight,
    paddingBottomMobileNavHeight: mobileNavHeight + 9,
    mobileAllHeightIncludingTitleHeader: mobileTitleHeaderHeight + mobileNavHeight,
    mobileAllHeight: mobileHeaderHeight + mobileNavHeight,
  },
}
