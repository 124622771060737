import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Checkbox, FormControlLabel, useMediaQuery, useTheme, Button } from '@mui/material';
import { showTextChOrEn } from './utils';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

interface Product {
  title: string;
  url: string;
  price: string;
  reasons: string;
  imgUrl: string;
}

interface RecommendationCardProps {
  product: Product;
  onAddToCompare: (product: Product) => void;
  handleEnquiry: (product: Product) => void;
  isCompared: boolean;
  isCompareMethod: boolean;
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({ product, onAddToCompare, isCompared, handleEnquiry, isCompareMethod }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const priceString = String(product.price);
  const formattedPrice = priceString.startsWith('$') ? priceString : `$${priceString}`;

  const handleEnquiryClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleEnquiry(product);
  };

  return (
    <>
    {isMobile?
      <Card style={{ display: 'flex', flexDirection: 'column', width: '250px', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <a
          href={product.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px 30px 0px 30px', width: '100%' }}>
              <CardMedia
                component="img"
                style={{ width: '140px', height: '140px', objectFit: 'contain' }}
                image={product.imgUrl? product.imgUrl : '/assets/images/coming_soon.png'}
                alt={product.title}
              />
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexShrink: 1 }}>
                <p style={{ fontWeight: 600, color: 'black', textAlign: 'center', wordBreak: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                  {product.title}
                </p>
                <Box
                  fontSize={isMobile ? 16 : 19}
                  fontWeight={700}
                  mt={1}
                  sx={{ color: 'secondary.main' }}
                  style={{ textAlign: 'center', wordBreak: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}
                >
                  {formattedPrice}
                </Box>
              </Box>
            </Box>
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'black', width: '100%' }}>
              <Box style={{ width: '100%', textAlign: 'center' }}>
                  <Typography
                    fontSize={isMobile ? 12 : 14}
                    fontWeight={500}
                    mt={1}
                    style={{ textAlign: 'justify', color: 'black', padding: '10px', marginBottom: '20px', wordBreak: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal' }}
                  >
                    {product.reasons}
                  </Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 'auto' }}>
                <Button
                  variant="outlined"
                  startIcon={<QuestionAnswerOutlinedIcon />}
                  onClick={handleEnquiryClick}
                  style={{ color: 'black', borderColor: 'black', padding: '8px', width: '150px' }}
                >
                  {showTextChOrEn("询问", "Enquiry")}
                </Button>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px', color: 'black' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCompared}
                      onChange={() => onAddToCompare(product)}
                      style={{ color: 'black' }}
                    />
                  }
                  label={showTextChOrEn("加入对比", "Add to Compare")}
                  style={{ color: 'black' }}
                />
              </Box>
            </CardContent>
          </Box>
        </a>
      </Card>
      :
    <Card style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
    <a
      href={product.url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', flex: 1 }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', padding: '30px 30px 0px 30px' }}>
          <CardMedia
            component="img"
            style={{ width: '140px', height: '140px', objectFit: 'contain' }}
            image={product.imgUrl? product.imgUrl : '/assets/images/coming_soon.png'}
            alt={product.title}
          />
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: '16px', flexShrink: 1 }}>
            <Typography variant="h6" component="h6" style={{ fontWeight: 600, color: 'black' }}>
              {product.title}
            </Typography>
            <Box
              fontSize={isMobile ? 16 : 19}
              fontWeight={700}
              mt={1}
              sx={{ color: 'secondary.main' }}
            >
              {formattedPrice}
            </Box>
          </Box>
        </Box>
        <CardContent style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', color: 'black' }}>
          <Box style={{ flexGrow: 1 }}>
              <Typography
                fontSize={isMobile ? 12 : 14}
                fontWeight={500}
                mt={1}
                style={{ textAlign: 'justify', color: 'black', padding: '10px', marginBottom:'20px' }}
              >
                {product.reasons}
              </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
            <Button
              variant="outlined"
              startIcon={<QuestionAnswerOutlinedIcon />}
              onClick={handleEnquiryClick}
              style={{ color: 'black', borderColor: 'black', padding: '8px', width: '150px' }}
            >
              {showTextChOrEn("询问", "Enquiry")}
            </Button>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', color: 'black' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCompared}
                  onChange={() => onAddToCompare(product)}
                  style={{ color: 'black' }}
                />
              }
              label={showTextChOrEn("加入对比", "Add to Compare")}
              style={{ color: 'black' }}
            />
          </Box>
        </CardContent>
      </Box>
    </a>
  </Card>}
  </>
  );
}

export default RecommendationCard;