import './App.css';
import ChatWindow from './ChatWindow';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ChatWindow />
      </div>
    </ThemeProvider>
  );
}

export default App;
