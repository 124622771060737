import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { Box, TextField, Button, List, ListItem, Avatar, Typography, IconButton, useMediaQuery, useTheme, Grid, Card, CardMedia, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormData from 'form-data';
import LinearProgress from '@mui/material/LinearProgress';
import { showTextChOrEn } from './utils';
import { getLocalStorage } from './useStorage'

interface Message {
  id: number;
  text: string;
  sender: 'user' | 'bot';
  name: string;
  avatar: string;
}

interface Product {
  title: string;
  url: string;
  price: string;
  reasons: string;
  imgUrl: string;
}

interface EnquiryProps {
  onClose: () => void;
  store: string;
  sessionId: string;
  product: Product
  chatbotAPI: any;
}

const Enquiry: React.FC<EnquiryProps> = ({ onClose, store, sessionId, product, chatbotAPI}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const priceString = String(product.price);
  const formattedPrice = priceString.startsWith('$') ? priceString : `$${priceString}`;

  useEffect(() => {
    const loadingMessage: Message = {
      id: messages.length + 1,
      text: showTextChOrEn("您可以在此处询问该产品的参数、价格、特点等信息。例如:该产品有哪些亮点功能?","Feel free to ask about this product's specifications, features and more. For example: What are the highlight features of this product?"),
      sender: 'bot',
      name: 'Bot',
      avatar: '/path-to-bot-avatar.jpg',
    };
    setMessages((prevMessages: any) => [...prevMessages, loadingMessage]);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSend = async () => {
    setLoading(true);
    if (input.trim()) {
      const newMessage: Message = {
        id: messages.length + 1,
        text: input,
        sender: 'user',
        name: 'User',
        avatar: '/path-to-user-avatar.jpg',
      };
      setMessages([...messages, newMessage]);
      setInput('');

      try {
        const chatbotFormData = new FormData();
        chatbotFormData.append('language', getLanguageSetting());
        chatbotFormData.append('query', input);
        chatbotFormData.append('store', store);
        chatbotFormData.append('session_id', sessionId);
        chatbotFormData.append('product_url', product.url)
        const chatbotResponse = await chatbotAPI('POST','productEnquiry',chatbotFormData);

        const botResponse: Message = {
            id: messages.length + 1,
            text: chatbotResponse.data,
            sender: 'bot',
            name: 'Bot',
            avatar: '/path-to-bot-avatar.jpg',
          };
          setLoading(false);
          setMessages(prevMessages => [...prevMessages, botResponse]);
      } catch (error) {
        setLoading(false);
        console.error('Error sending message:', error);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const getLanguageSetting = () => {
    if (typeof window === 'undefined') {
      return 'en'
    }
  
    if (getLocalStorage('language') === 'zh-cn') {
      return 'zh-cn'
    }
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: { xs: '100%', sm: 750 },
        maxHeight: '100vh',
        width: { xs: '100%', sm: 650 },
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        pb: { xs: 8, sm: 0 } 
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: { xs: '4px 8px', sm: '8px 16px' }, // Adjust padding for mobile
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f5f5f5',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <IconButton size="small" onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{ marginLeft: '16px', fontSize: isMobile ? '1rem' : '1.25rem' }} // Adjust font size for mobile
        >
          {showTextChOrEn(store + " 产品咨询", store+ " Enquiry")}
        </Typography>
      </Box>
      <Grid item xs={12} style={{ marginBottom: '30px' }}>
      <a
        href={product.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Card style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 'auto', width: '100%' }}>
          <CardMedia
            component="img"
            style={{ width: '100px', height: '80px', objectFit: 'contain' }}
            image={product.imgUrl}
            alt={product.title}
          />
          <CardContent style={{ textAlign: 'left', flex: 1 }}>
            <Typography
              fontSize={isMobile ? 18 : 22} // Adjust font size for mobile
              fontWeight={600}
            >
              {product.title}
            </Typography>
            <Box
              fontSize={isMobile ? 16 : 19} // Adjust font size for mobile
              fontWeight={700}
              mt={1}
              sx={{ color: 'secondary.main' }}
            >
              {formattedPrice}
            </Box>
          </CardContent>
        </Card>
      </a>
    </Grid>
      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          marginBottom: '16px',
        }}
      >
        {messages.map((msg) => (
          <ListItem
            key={msg.id}
            sx={{
              justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {msg.sender === 'bot' && (
              <Avatar alt={msg.name} src={msg.avatar} sx={{ marginRight: 2 }} />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
              }}
            >
              <Typography variant="subtitle2">{msg.name}</Typography>
              <Box
                sx={{
                  backgroundColor: msg.sender === 'user' ? '#e0f7fa' : '#e8eaf6',
                  borderRadius: '8px',
                  padding: '8px',
                  marginBottom: '8px',
                }}
              >
                <Typography variant="body2">{msg.text}</Typography>
              </Box>
            </Box>
            {msg.sender === 'user' && (
              <Avatar alt={msg.name} src={msg.avatar} sx={{ marginLeft: 2 }} />
            )}
          </ListItem>
        ))}
        <div ref={messagesEndRef} />
      </List>
      {loading && (
        <Box sx={{ width: '100%', mt: 3 }}>
          <LinearProgress />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          m: 1
        }}
      >
        <TextField
          variant="outlined"
          value={input}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          sx={{ flexGrow: 9 }} 
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          sx={{ flexGrow: 1, marginLeft: '10px' }} 
        >
          {showTextChOrEn('发送', 'Send')}
        </Button>
      </Box>
    </Box>
  );
};

export default Enquiry;








