import { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Typography, Divider, IconButton } from '@mui/material';
import { ChevronLeft, Delete } from '@mui/icons-material';
import { showTextChOrEn } from './utils';

interface SessionData {
  sessionId: string;
  messages: any[];
  timestamp: string;
  compare:boolean;
  store: string;
}

interface SidebarProps {
  messages: any;
  setMessages: (messages: any[]) => void;
  sessionId: string;
  setSessionId: (sessionId: string) => void;
  historyOpen: boolean;
  setHistoryOpen: any;
  setIsCompareMethod:any;
  store: string;
}

const Sidebar: React.FC<SidebarProps> = ({ messages, setMessages, sessionId, setSessionId, historyOpen, setHistoryOpen, setIsCompareMethod, store }) => {
  const [sessionDataList, setSessionDataList] = useState<SessionData[]>([]);

  useEffect(() => {
    const savedSessionData = localStorage.getItem('sessionData');
    if (savedSessionData) {
      const parsedData = JSON.parse(savedSessionData);
      if (Array.isArray(parsedData)) {
        setSessionDataList(parsedData);
      } else {
        setSessionDataList([parsedData]);
      }
    }
  }, [messages, sessionId]);

  const getCategorizedSessions = () => {
    const today: SessionData[] = [];
    const yesterday: SessionData[] = [];
    const thisWeek: SessionData[] = [];
    const earlier: SessionData[] = [];

    const now = new Date();
    const startOfToday = new Date(now.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfYesterday.getDate() - 1);
    const startOfThisWeek = new Date(startOfToday);
    startOfThisWeek.setDate(startOfThisWeek.getDate() - startOfThisWeek.getDay());

    sessionDataList
    .filter((session) => session.store === store)
    .forEach((session) => {
      const sessionDate = new Date(session.timestamp);
      if (sessionDate >= startOfToday) {
        today.push(session);
      } else if (sessionDate >= startOfYesterday) {
        yesterday.push(session);
      } else if (sessionDate >= startOfThisWeek) {
        thisWeek.push(session);
      } else {
        earlier.push(session);
      }
    });

    const sortDescending = (a: SessionData, b: SessionData) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

    today.sort(sortDescending);
    yesterday.sort(sortDescending);
    thisWeek.sort(sortDescending);
    earlier.sort(sortDescending);

    return { today, yesterday, thisWeek, earlier };
  };

  const { today, yesterday, thisWeek, earlier } = getCategorizedSessions();

  const handleListItemClick = (session: SessionData) => {
    setMessages(session.messages);
    setSessionId(session.sessionId);
    setIsCompareMethod(session.compare)
  };

  const handleDeleteSession = (sessionId: string) => {
    const updatedSessionDataList = sessionDataList.filter(session => session.sessionId !== sessionId);
    setSessionDataList(updatedSessionDataList);
    localStorage.setItem('sessionData', JSON.stringify(updatedSessionDataList));
  };

  const renderSessionList = (sessions: SessionData[], title: string) => (
    <>
      <Typography variant="subtitle1" sx={{ padding: 2 }}>
        {title}
      </Typography>
      {sessions.map((session) => (
        <ListItem button key={session.sessionId}>
          <ListItemText primary={`${new Date(session.timestamp).toLocaleString()}`} onClick={() => handleListItemClick(session)} />
          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSession(session.sessionId)}>
            <Delete/>
          </IconButton>
        </ListItem>
      ))}
      <Divider />
    </>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: historyOpen ? 240 : 0,
        flexShrink: 0,
        transition: 'width 0.3s',
        [`& .MuiDrawer-paper`]: { width: historyOpen ? 240 : 0, boxSizing: 'border-box', transition: 'width 0.3s' },
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
        <Typography variant="h6">
          {showTextChOrEn("历史会话", "Chat History")}
        </Typography>
        <IconButton onClick={() => setHistoryOpen(!historyOpen)}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      {historyOpen && (
        <List>
          {renderSessionList(today, 'Today')}
          {renderSessionList(yesterday, 'Yesterday')}
          {renderSessionList(thisWeek, 'This Week')}
          {renderSessionList(earlier, 'Earlier')}
        </List>
      )}
    </Drawer>
  );
};

export default Sidebar;