import React from 'react';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Button, IconButton, Divider } from '@mui/material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { showTextChOrEn } from './utils';

interface Product {
  title: string;
  url: string;
  price: string;
  reasons: string;
  imgUrl: string;
}

interface ComparisonListProps {
  comparisonList: Product[];
  onClear: () => void;
  onCompare: () => void;
  onRemove: (productUrl: string) => void;
}

const ComparisonList: React.FC<ComparisonListProps> = ({ comparisonList, onClear, onCompare, onRemove }) => {
  
  const formattedPrice = (price:any) =>{
    const priceString = String(price);
    return priceString.startsWith('$') ? priceString : `$${priceString}`;
  }

  return (
    <Draggable>
      <Box
        sx={{
          position: 'fixed',
          bottom: '10px',
          right: '20px',
          width: '400px',
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
          zIndex: 1000,
          padding: '8px',
          cursor: 'move',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            pb: 1,
          }}
        >
          <Typography variant="h6">
            {showTextChOrEn(`[${comparisonList.length}/5] 对比框`, `[${comparisonList.length}/5] Comparison List`)}
          </Typography>
          <IconButton size="small" onClick={onClear}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {comparisonList.map((product) => (
            <React.Fragment key={product.url}>
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => onRemove(product.url)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar variant="square" src={product.imgUrl? product.imgUrl : '/assets/images/coming_soon.png'} sx={{ width: 80, height: 80, marginRight:'30px' }} />
                </ListItemAvatar>
                <ListItemText primary={product.title} secondary={formattedPrice(product.price)} />
              </ListItem>
              <Divider variant="middle" />
            </React.Fragment>
          ))}
        </List>
        {comparisonList.length >= 2 && (
          <Button
            variant="contained"
            color="primary"
            onClick={onCompare}
            sx={{ mt: 2, width: '100%' }}
          >
            {showTextChOrEn("对比", "Compare")}
          </Button>
        )}
        {comparisonList.length > 0 && (
          <Button
            variant="outlined"
            color="error"
            onClick={onClear}
            sx={{ mt: 1, width: '100%' }}
          >
            {showTextChOrEn("清空对比栏", "Clear Comparison List")}
          </Button>
        )}
      </Box>
    </Draggable>
  );
};

export default ComparisonList;











