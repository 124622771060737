import React, { useState, useEffect } from 'react';
import { showTextChOrEn } from './utils';

const ProgressBar: React.FC<{ duration: number }> = ({ duration }) => {
  const steps = [
    showTextChOrEn("问题汇总", "Summary"),
    showTextChOrEn("问题分析", "Analysis"),
    showTextChOrEn("数据库检索", "Database Search"),
    showTextChOrEn("答案生成", "Answer Generation")
  ];
  
  const [progress, setProgress] = useState(0);
  const stepRatios = [0.2, 0.3, 0.5]; 
  const stepTimes = stepRatios.map((ratio) => duration * ratio); 

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 1000 / 60);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {steps.map((step, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <div
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: progress >= stepTimes.slice(0, index).reduce((a, b) => a + b, 0) ? '#ffa500' : '#ccc',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              {progress >= stepTimes.slice(0, index).reduce((a, b) => a + b, 0) ? '✔' : '○'}
            </div>
            <div style={{ marginLeft: '8px', fontSize: '16px' }}>{step}</div>
            {index < steps.length - 1 && (
              <div
                style={{
                  width: '50px',
                  height: '2px',
                  backgroundColor: '#ccc',
                  margin: '0 8px',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    backgroundColor: '#ffa500',
                    width: `${Math.min((progress - stepTimes.slice(0, index).reduce((a, b) => a + b, 0)) / stepTimes[index] * 100, 100)}%`,
                    position: 'absolute',
                    left: 0,
                    transition: 'width 0.5s ease-in-out',
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;