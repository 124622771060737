export const getLocalStorage = (item) => {
  if (typeof localStorage === 'undefined') {
    return null
  }
  return localStorage.getItem(item)
}

export const setLocalStorage = (item, value) => {
  localStorage.setItem(item, value)
}

export const getSessionStorage = (item) => {
  if (typeof sessionStorage === 'undefined') {
    return null
  }
  return sessionStorage.getItem(item)
}

export const setSessionStorage = (item, value) => {
  sessionStorage.setItem(item, value)
}

const useStorage = () => {}

export default useStorage
