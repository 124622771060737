import React, { useState } from 'react';
import { Box, Typography, Paper, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { showTextChOrEn } from './utils';
import Recommendation from './Recommendation';

const floatingButtonStyles = (delay: number): React.CSSProperties => ({
  animation: `floatIn 0.5s ease-in-out forwards`,
  animationDelay: `${delay}s`,
  opacity: 0,
});

const wipeUpStyles: React.CSSProperties = {
  animation: 'wipeUp 1s ease-in-out forwards',
  opacity: 0,
};

const ChatWindow = () => {
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [store, setStore] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenChatbot = (store: string) => {
    setStore(store);
    setChatbotOpen(true);
  };

  return (
    <div>
      <style>
        {`
          @keyframes floatIn {
            0% {
              transform: translateX(-100%);
              opacity: 0;
            }
            100% {
              transform: translateX(0);
              opacity: 1;
            }
          }

          @keyframes wipeUp {
            0% {
              transform: translateY(100%);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
              opacity: 1;
            }
          }
        `}
      </style>
      <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              textAlign: 'left',
            }}
          >
            <img
              src="/assets/images/chatbot.png"
              alt="Robot"
              style={{ width: "100px", height: "100px", ...wipeUpStyles }}
            />
            <Typography variant="h6" gutterBottom align="center" sx={{ m: 3, ...wipeUpStyles }}>
              {showTextChOrEn("PriceGrabber智能助理", "PriceGrabber Assistant")}
            </Typography>
            {isMobile ?           
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                mt: 5
              }}
            >
              {/* Row 1 */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Card
                  sx={{
                    ...floatingButtonStyles(0.2),
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenChatbot("PBTech")}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src="/assets/icons/stores/Pbtech.png"
                    alt="PBTech"
                    sx={{
                      width: '80%',
                      height: '80%',
                      objectFit: 'contain',
                    }}
                  />
                    <Typography variant="body2">PBTech</Typography>
                  </CardContent>
                </Card>
              </Box>
              {/* Row 2 */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2, mt:3 }}>
                <Card
                  sx={{
                    ...floatingButtonStyles(0.4),
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    mr:3
                  }}
                  onClick={() => handleOpenChatbot("Bunnings")}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src="/assets/icons/stores/Bunnings.png"
                    alt="Bunnings"
                    sx={{
                      width: '75%',
                      height: '75%',
                      objectFit: 'contain',
                    }}
                  />
                    <Typography variant="body2">Bunnings</Typography>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    ...floatingButtonStyles(0.6),
                    width: '100px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOpenChatbot("Chemistwarehouse")}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src="/assets/icons/stores/Chemistwarehouse.png"
                    alt="Chemistwarehouse"
                    sx={{
                      width: '50%',
                      height: '50%',
                      objectFit: 'contain',
                    }}
                  />
                    <Typography variant="body2">Chemist</Typography>
                    <Typography variant="body2">Warehouse</Typography>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          :
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 4, // Increase gap
            }}
          >
            <Card
              sx={{
                ...floatingButtonStyles(0.2),
                width: '120px',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                m: 2, // Add margin
              }}
              onClick={() => handleOpenChatbot("PBTech")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src="/assets/icons/stores/Pbtech.png"
                  alt="PBTech"
                  sx={{
                    width: '80%',
                    height: '80%',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="body2">PBTech</Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                ...floatingButtonStyles(0.4),
                width: '120px',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                m: 2, // Add margin
              }}
              onClick={() => handleOpenChatbot("Bunnings")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src="/assets/icons/stores/Bunnings.png"
                  alt="Bunnings"
                  sx={{
                    width: '75%',
                    height: '75%',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="body2">Bunnings</Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                ...floatingButtonStyles(0.6),
                width: '120px',
                height: '120px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                m: 2, // Add margin
              }}
              onClick={() => handleOpenChatbot("Chemistwarehouse")}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src="/assets/icons/stores/Chemistwarehouse.png"
                  alt="Chemistwarehouse"
                  sx={{
                    width: '50%',
                    height: '50%',
                    objectFit: 'contain',
                  }}
                />
                <Typography variant="body2">Chemist</Typography>
                <Typography variant="body2">Warehouse</Typography>
              </CardContent>
            </Card>
        </Box>
          }
          </Box>
        </Paper>

      {chatbotOpen && (
        <Recommendation onClose={() => setChatbotOpen(false)} store={store} />
      )}
    </div>
  );
};

export default ChatWindow;